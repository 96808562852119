import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import YouTube from 'react-youtube';

const PhotosIndex = ({ location }) => {
  return (
    <Layout fullMenu location={location} title="Photos">
      <article id="main">
        <header className="photos-header">
          <h2>Photos</h2>
          <p>Here are our photos!</p>
        </header>
        <section className="wrapper style5 photosPage">
          <div className="inner">
            <div className="box alt">
              <YouTube videoId="Hkn3PDO1MXo" opts={{ width: '100%' }} />
            </div>
            <div className="box alt maincontent">
              <p>
                Thank you to everyone who took the time to come and celebrate
                our special day with us. We are honored and blessed by your
                presence.
              </p>
              <p>Click on the albums to see our photos!</p>
              <div className="card-grid">
                <Link to="/photos/tea-ceremony" className="card">
                  <div
                    className="card__background"
                    style={{
                      backgroundImage: 'url(/CoverTeaCeremony.jpg)',
                    }}
                  ></div>
                  <div className="card__content">
                    <p className="card__heading">Tea Ceremony</p>
                  </div>
                </Link>

                <Link to="/photos/church" className="card">
                  <div
                    className="card__background"
                    style={{
                      backgroundImage: 'url(/CoverChurch.jpg)',
                    }}
                  ></div>
                  <div className="card__content">
                    <p className="card__heading">Church</p>
                  </div>
                </Link>

                <Link to="/photos/reception" className="card">
                  <div
                    className="card__background"
                    style={{
                      backgroundImage: 'url(/CoverReception2.jpg)',
                    }}
                  ></div>
                  <div className="card__content">
                    <p className="card__heading">Reception</p>
                  </div>
                </Link>
              </div>

              <hr />
              <p>Guests</p>
              <div className="card-grid--guests">
                <Link to="/photos/reception-guests" className="card">
                  <div
                    className="card__background"
                    style={{
                      backgroundImage: 'url(/CoverEntrance.jpg)',
                    }}
                  ></div>

                  <div className="card__content">
                    <p className="card__heading">Entrance Photos</p>
                  </div>
                </Link>

                <Link to="/photos/photobooth" className="card">
                  <div
                    className="card__background"
                    style={{
                      backgroundImage: 'url(/CoverPhotobooth.jpg)',
                    }}
                  ></div>
                  <div className="card__content">
                    <p className="card__heading">Reception Photobooth</p>
                  </div>
                </Link>
              </div>

              <hr />
              <div className="card-grid--engagement">
                <Link to="/photos/engagement" className="card">
                  <div
                    className="card__background"
                    style={{
                      backgroundImage: 'url(/CoverEngagement.jpg)',
                    }}
                  ></div>

                  <div className="card__content">
                    <p className="card__heading">Engagement Pictures</p>
                  </div>
                </Link>
              </div>

              <hr />
              <p>
                If you have photos or videos from our wedding day that you would
                like to share, please upload them to this album: <br />
                <a
                  href="https://photos.app.goo.gl/LZgXjtuwph2bWiX87"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button primary"
                >
                  DKN Wedding
                </a>
              </p>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default PhotosIndex;
